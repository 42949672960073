<template>
  <div class="wrapper">
    <div class="logo-box">
      <img v-if="this.type == '200'" src="@/assets/successful.png" alt="" />
      <img v-else src="@/assets/error.png" alt="" />
      <p>退保成功</p>
    </div>
    <div class="time-msg">
      <p>退保生效日期2022-03-08 15时</p>
    </div>
    <p class="msg">您的退保保费（1000.00）会原路返回到支付账户</p>
    <div class="btn-box">
      <p class="back-btn">返回我的保单</p>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      type:'',
    }
  },
  mounted(){
    this.type = this.$router.query.type;
  }
}
</script>

<style scoped lang='scss'>
.wrapper {
  padding: 0 15px;
  overflow: hidden;
  .logo-box {
    width: 134px;
    height: 94px;
    margin: 0 auto;
    // margin: 100px 139px 43px 0px;
    margin-top: 70px;
    img {
      width: 67px;
      height: 47px;
      margin: 0 auto;
    }
    p {
      font-size: 17px;
      color: #666666;
      margin-top: 10px;
      height: 24px;
      line-height: 24px;
      text-align: center;
    }
  }
  .time-msg {
    width: 345px;
    height: 48px;
    line-height: 48px;
    border-radius: 4px;
    background: #f7f7f7;
    text-align: center;
    margin-top: 25px;
    p {
      font-size: 14px;
      color: #666666;
    }
  }
  .msg {
    font-size: 13px;
    color: #5aa8e8;
    height: 13px;
    line-height: 13px;
    margin-top: 14px;
    text-align: center;
  }
  .btn-box {
    .back-btn {
      margin: 0 auto;
      margin-top: 73px;
      width: 325px;
      height: 45px;
      line-height: 45px;
      background: #ff7418;
      border-radius: 8px;
      text-align: center;
      color: #ffffff;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>